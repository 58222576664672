
.goto-SLIDE_LEFT {
    animation: slideLeft 1s;
}

.goto-SLIDE_RIGHT {
    animation: slideRight 1s;
}

.goto-FADE_OUT {
    animation: fadeOut 1s;
}

.goto-SLIDE_DOWN {
    animation: slideDown 1s;
}

.goto-SLIDE_UP {
    animation: slideUp 1s;
}

@keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}


@keyframes slideDown {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(100%);
        opacity: 0;
    }
}


@keyframes slideLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes slideRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}