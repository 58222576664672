.button {
    border: none;
    border-radius: 3rem;
    font-weight: bold;
    transition: all .25s ease-in-out;
    width: 320px;
    height: 96px;
    cursor: pointer;
    outline: none;
    @extend .flex-center;

    
    @media (min-width: $screen-lg) {
        width: 360px;
        height: 120px;
        border-radius: 4rem;
        font-size: 2rem;

        &--text {
            &-default {
                font-size: 2rem !important;
            }    
        }
    }

    @media (max-width: $screen-sm) {
        width: 250px;
        height: 70px;
    }

    img{
        transition: all .25s ease-in-out;
    }

    // BUTTON TYPES
    &--primary {
        background-color: $button-green;
        box-shadow: 0 4px 0 $button-green-dark;
        color: #fff;
    }

    &--primary:hover {
        background-color: $button-green-dark;
    }

    &--secondary{
        background-color: $button-red;
        box-shadow: 0 4px 0 $button-red-dark;
        color: #fff;        
    }

    &--secondary:hover{
        background-color: $button-red-dark;      
    }

    &--primary-small {
        width: 145px;
        background-color: $button-green;
        box-shadow: 0 4px 0 $button-green-dark;
        color: #fff;
    }

    &--primary-small:hover {
        background-color: $button-green-dark;
    }

    &--secondary-small{
        width: 145px;
        background-color: $button-red;
        box-shadow: 0 4px 0 $button-red-dark;
        color: #fff;        
    }

    &--secondary-small:hover{
        width: 145px;
        background-color: $button-red-dark;      
    }



    &--simple {
        background-color: transparent;
        border-color: #fff !important;
        color: #fff;

        &.button{
            &--active {
                background-color: #fff;
                color: $text-color-main;
            }
        }
    }

    &--disabled {
        opacity: .2;
    }
    // BUTTON TYPES END

    &--text {
        &-default {
            font-size: 1.5rem;
        }
        &-small {
            font-size: 1.2rem;
        }
    }

    &--icon-only {
        height: 4rem;
        width: 4rem;
        padding: 0;
        display: flex;
        justify-content: center;
        
        .button__icon {
            margin-left: 0;
        }   
    }

    &--outlined {
        border: 1px solid ;

        &.button {
            &--outline-colored {
                border-color: $bg-blue;
            }
            &--outline-light{
                border-color: $bg-blue;
            }
        }
    }

    &--bold {
        font-weight: bold;
    }

    &--normal {
        font-weight: normal;
    }

    &--circle {
        border-radius: 50%;
    }

    &__icon, .circle-arrow{
        margin-left: 1.5rem;
    }

    &--icon-left {
        flex-flow: row-reverse;

        img{
            margin-right: 1rem;
            margin-left: initial;
        }
    }
}