.colorvision-test, .colorvision-test-instructions {
    
    h1, h2 {
        color: #FFF;
        font-family: $font-filson-pro;
    }

    h2 {
        margin-bottom: 1em;
    }

    .flex-col {
        align-items: center;
        height:100%;
    }

    .flex-row {
        align-self: flex-start;
        align-items: center;
        
        img {
            z-index: 1;
        }
    }

    .swipeInstruction {
        font-size: 24px;
        color: #FFF;
    }
    
    &__test__progressbar-container {
        @extend .flex-center;
        margin: -0.5em 0 1em;
    }

    &__test__image-container {
        flex: 1;
        width: 90%;
        @extend .flex-center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 0px $button-blue;
        border-radius: 30px;
        opacity: 1;

        img {
            width: 90%;
        }

        &--animation-expand {
            animation: imageContainerExpand 500ms forwards;
        }
    }

    &__answer__grid {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 90%;
        gap: 2em;
        padding: 0.25em;
    }

    &__grid-item {
        @extend .flex-center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 0px $button-blue;
        border-radius: 15px;
        opacity: 1;

        h1 {
            color: #000;
            font-size: 120px;
        }
    }

    @keyframes imageContainerExpand {
        from {
            position: relative;
        }
        to {
            width: 100%;
            height: 100%;
            position: fixed;
            border-radius: 0px;
        }

    }

    @media (max-width: $screen-lg), (min-width: $screen-lg) {
        padding: 3em 2em; 
        
        .flex-row {
            h1 {
                font-size: 2.5em;
                margin-left: 1em;
            }
        }

        &__test__progressbar-container {
            width: 90%;
    
            img {
                width: 80%;
            }
        }
    }
    
    @media (max-width: $screen-md) {
        .flex-row {
            h1 {
                font-size: 2em;
                margin-left: 1em;
            }
        }

    }

    @media (max-width: $screen-sm) {
        padding: 1.5em 1em; 

        h2 {
            font-size: 1em;
            margin-bottom: 0.5em;
        }

        .flex-row {
            h1 {
                font-size: 1.25em;
                margin-left: 0.5em;
            }
        }

        .swipeInstruction {
            font-size: 16px;
        }

        &__test__progressbar-container {
            width: 100%;
    
            img {
                width: 100%;
            }
        }

        &__answer__grid {
            gap: 1em; 
            img {
                width: 50%;
            }
        }
    }
    
    @media (max-width: $screen-xs) {
        &__grid-item {
            h1 {
                font-size: 100px;
            }
        }
    }
}