.instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    height: 90%;

    h2 {
        color: #FFFFFF;
        font-family: $font-filson-pro;
        opacity: 1;
    }

    caption {
        color: #FFFEFF;
        letter-spacing: 0px;
        display: inline;
        text-align: center;
    }

    .instruction__image {
        width: 100%;

        img {
            margin-inline: auto;
        }
    }

    .instruction__title {
        h2 {
            display: block;
            text-align: center;
        }

    }
    
    .instruction__description {
        text-align: center;
        max-width: 561px;
    }

    .instruction__assistance-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255, 0.8);
        border-radius: 8px;

        caption {
            color: #688643;
        }
    }

    .instruction__assistance--warning {
        border-radius: 50%;
        background: linear-gradient(to right, #8AB85B 0%,  #8AB85B 50%,  #688643 50%, #688643 100%);

        h2 {
            color: #FFF;
            opacity: 1;
            height: 100%;
            font-size: 2em;
            @extend .flex-center;
        }
    }

    .instruction__assistance--text {
        text-align: left;
    }

    .instructions__swipe {
        position: relative;
        top: 15%;
        color: #FFFEFF;
    }

    .instructions__button {
        flex: 1;
        padding-bottom: 0.25em;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        button {
            letter-spacing: 1px;
        }
    }

    .breadcrumb__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
    }

    .breadcrumb, .breadcrumb--selected {
        height: 10px;
        background: #FFFFFF;
        border-radius: 5px;
        opacity: 1;
    }

    .breadcrumb {
        width: 10px;
    }

    .breadcrumb--selected {
        width: 30px;
    }

    @media (max-width: $screen-lg), (min-width: $screen-lg) {

        h2 {
            font-size: 2em;
        }

        caption {
            font-size: 25px;
        }

        .instruction__image {
            margin: 8em 0 3em;

            img {
                width: 80%;
            }
        }

        .instruction__title {
            margin: 3em 0 0;
        }

        .instruction__description {
            margin-bottom: 93.3px;
        }
    
        .instruction__assistance-container {
            padding: 2em;
            gap: 1em;
        }
    
        .instruction__assistance--warning {
            height: 50px;
            width: 50px;
        }


    }
    
    @media (max-width: $screen-md) {


    }

    @media (height: 954px) and (width: 768px) {

        .instruction__image {
            margin: 3em 0 3em;

            img {
                width: 80%;
            }
        }

    }

    @media (max-width: $screen-sm) {
        h2 {
            font-size: 1.5em;
        }

        caption {
            font-size: 0.875em;
        }

        .instruction__image {
            margin: 1em 0;

            img {
                width: 100%;
            }
        }

        .instruction__title {
            margin: 1em 0;
        }

        .instruction__description {
            margin-bottom: 1em;
        }
    
        .instruction__assistance-container {
            padding: 0.75em;
            gap: 0.75em;
        }
    
        .instruction__assistance--warning {
            height: 40px;
            width: 40px;

            h2 {
                font-size: 1.75em;
            }
        }

        .instruction__assistance--text {
            width: 85%;
        }
    
        .breadcrumb--selected {
            width: 20px;
        }
    }
    
    @media (max-width: $screen-xs) {
   
    }


}

// @media screen and (max-height: 670px) and (max-width: 360px) {
//     .instructions {
        
//         height: 90%;
//     }
// }