.badge {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    h1 {
        font-family: $font-filson-pro;
        text-align: center;
    }

    p, caption {
        display: inline;
        text-align: center;
    }

    p {
        color: #8AB85B;
        font-weight: 600;
    }
}   

@media (max-width: $screen-lg), (min-width: $screen-lg) {
    .badge {
        h1 {
            font-size: 3rem;
        }
        p, caption {
            font-size: 2rem;
        }
    }
}

@media (max-width: $screen-md) {
    .badge {
        h1 {
            font-size: 2.5rem;
        }
        p, caption {
            font-size: 1.5rem;
        }
    }
}


@media (max-width: $screen-sm) {
    .badge {
        h1 {
            font-size: 1.75rem;
        }
        p, caption {
            font-size: 1rem;
        }
    }
}

@media (max-width: $screen-xs) {
    .badge {
        h1 {
            font-size: 1rem;
        }
        p, caption {
            font-size: 0.785rem;
        }

        img {
            width: 60%;
        }
    }
}