.dashboard, .profile, .my-badges, .my-results {

    .navigation-wrapper {
        opacity: 0.7;
        background-color: #FFFFFF;
        border-radius: 5rem;
        height: 100%;
        @extend .flex-center;

    }

    .navigation__item {
        @extend .flex-center;
        margin: 0 1rem;
        border-radius: 2rem;
    }

    .selected {
        background-color: $button-red-dark;
    }
}

@media (max-width: $screen-lg), (min-width: $screen-lg) {
    .dashboard, .profile, .my-badges, .my-results {
        .navigation__item {
            height: 120px;
            width: 120px;
    
            img {
             
                height: 60px;
                width: 60px;
            }
        }
    }

}

@media (min-width: $screen-md) {
    .dashboard, .profile, .my-badges, .my-results  {
        .navigation__item {
            height: 100px;
            width: 100px;
        }
    }
}


@media (max-width: $screen-sm) {
    .dashboard, .profile, .my-badges, .my-results  {
        .navigation__item {
            height: 60px;
            width: 60px;
            border-radius: 1.25rem;
            img {
             
                height: 36px;
                width: 36px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    .dashboard, .profile, .my-badges, .my-results  {
        .navigation__item {
            height: 50px;
            width: 50px;
            border-radius: 1rem;
            img {
             
                height: 30px;
                width: 30px;
            }
        }
    }
}