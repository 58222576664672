.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    h1 {
        font-family: $font-filson-pro;
        margin: 1em 0;
    }

    caption {
        display: inline;
        color: #02020A;
    }

    .popup__button {
        flex: 1;
        display: flex;
        align-items: flex-end;
    }

    
    @media (max-width: $screen-lg), (min-width: $screen-lg) {
        caption {
            font-size: 1.25rem;
        }
    }
    
    @media (max-width: $screen-md) {

    }
    
    
    @media (max-width: $screen-sm) {
        h1 {
            margin: 0.75em 0;
        }

        caption {
            font-size: 1rem;
        }
    }
    
    @media (max-width: $screen-xs) {

    }
}