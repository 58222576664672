.splash-screen {
    &__container {        
        @extend .flex-center;
    }
    
    &__evf-logo {
        @extend .position-center-fixed;
        width: 80%;
    }

    &__ozzy-bear {
        position: fixed;
        top: 100%;
        opacity: 0;

        &--animate {
            animation: slideUpOzzy 250ms forwards; 
        }
    }

    @keyframes slideUpOzzy {
        from {
            transform: translateY(0%);
            opacity: 0;
        }
        to {
            transform: translateY(-50%);
            opacity: 1;
        }
    }
    
}