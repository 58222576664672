.dashboard {

    .version-text {
        position: absolute;
        top: 95%;
    }

    .flex-col {
        justify-content: space-between;
        gap: 1em;
        height: 100%;
    }

    .flex-row {
        align-items: center;
    }

    .avatar {
        @extend .flex-center;
        background-color: #E2E3D0;
        border: 3px solid #ffff;
        border-radius: 50%;
        width: 80px;
        height: 80px;

        img {
            margin-top: 1.25rem;
            width: 70%;
        }
    }

    .message-wrapper {
        padding-left: 1rem;

        h1,
        caption {
            display: inline-block;
            font-family: $font-filson-pro;
            letter-spacing: 0;
        }

        h1 {
            line-height: 26px;
            color: $theme;
            font-size: 1.5rem;
        }

        caption {
            color: #090000;
            line-height: 1.5rem;
            font-size: 0.875rem;
        }
    }


    .bell-wrapper {
        flex-grow: 1;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }

    .bell {
        width: 40px;
        height: 40px;
        background-color: $button-red;
        box-shadow: 0 4px 0 $button-red-dark;
        border-radius: 50%;

        img {
            padding: 0.35rem;
            cursor: pointer;
            width: 100%;
        }
    }

    .notification__count {
        @extend .flex-center;
        position: absolute;
        color: #fff;
        background-color: $button-red;
        border: 3px solid $button-red-dark;
        border-radius: 50%;
    }

    .new-player-button {
        font-size: 18px;
        width: 150px;
        height: 50px;
    }

    &__swipeable-1,
    &__swipeable-2,
    &__swipeable-3 {
        height: 100%;
        background-repeat: round;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        p {
            margin: 0 0.5em;
            text-align: center;
            font-family: $font-filson-pro;
            color: #fff;
        }
    }

    &__swipeable-1 {
        background-image: url('../../../assets/images/background-green.png');

        button {
            font-family: $font-filson-pro;
            background-color: #fff;
            box-shadow: 0 4px 0 #4D8425;
            color: #4D8425;
        }
    }

    &__swipeable-2 {
        background-image: url('../../../assets/images/background-blue.png');

        button {
            font-family: $font-filson-pro;
            background-color: #fff;
            box-shadow: 0 4px 0 $bg-blue;
            color: $bg-blue;
        }
    }


    &__swipeable-3 {
        background-image: url('../../../assets/images/background-orange.png');

        button {
            font-family: $font-filson-pro;
            background-color: #fff;
            box-shadow: 0 4px 0 #E38349;
            color: #E38349;
        }
    }

    &__bear {
        position: relative;
        width: 100%;
        pointer-events: none;
    }

    &__bear__body {
        width: 70%;
        position: relative;
        margin: 0 auto;
    }

    &__bear__speech-container {
        width: 100%;
        aspect-ratio: 1/1;
        position: absolute;
        top: 5%;
        left: 100%;
        transform: translate(-50%, -50%);
    }

    &__bear__speech-wrapper {
        height: 100%;
        position: relative;
    }

    &__bear__speech {
        position: absolute;
        // aspect-ratio: 1/1;
        padding-left: 5px;
        padding-right: 5px;
        transform: translateY(-50%);
        // width: auto;
        background-color: #f3c960;
        color: #fff;
        // text-align: center;
        border-radius: 6px;

        p {
            font-family: $font-filson-soft;
        }
    }

    &__bear__speech::after {
        content: " ";
        position: absolute;
        top: 99%;
        left: 5%;
        margin-left: -5px;
        border-width: 15px;
        border-style: solid;
        border-color: #f3c960 transparent transparent transparent;
    }

    @media (min-width: $screen-lg) {
        padding: 2rem 4rem;

        .flex-col {
            justify-content: space-evenly;
        }

        .avatar {
            width: 160px;
            height: 160px;
        }

        .message-wrapper {
            padding-left: 2rem;

            h1 {
                font-size: 2.5rem;
            }

            caption {
                font-size: 2rem;
                padding-top: 20px;
            }
        }

        .bell {
            width: 80px;
            height: 80px;
        }

        .notification__count {
            top: 5%;
            right: -5%;
            font-size: 2rem;
            height: 50px;
            width: 50px;
        }

        .new-player-button {
            width: 300px;
            height: 100px;
        }

        &__swipeable-1,
        &__swipeable-2,
        &__swipeable-3 {
            border-radius: 7em;

            p {
                font-size: 3rem;
            }

            button {
                height: 70pt;
                width: 240pt;
                font-size: 28px;

                img {
                    height: 36px;
                    width: 36px;
                    margin-left: 0.5em;
                }
            }
        }

        &__bear {
            margin: 4em 0 0 0;
            max-width: 18rem;
        }

        &__bear__speech {
            top: 30%;
            left: 5%;

            p {
                font-size: 1.75rem;
                text-align: center;
                color: #090000;
                margin: 0;
            }
        }
    }

    @media (max-width: $screen-md) {
        padding: 2rem;

        .flex-col {
            justify-content: space-evenly;
        }

        .avatar {
            width: 100px;
            height: 100px;
        }

        .message-wrapper {
            padding-left: 2rem;

            h1 {
                font-size: 2rem;
            }

            caption {
                font-size: 1.5rem;
                padding-top: 10px;
            }
        }

        .bell {
            width: 60px;
            height: 60px;
        }

        .notification__count {
            top: 0%;
            right: -5%;
            font-size: 1.75rem;
            height: 40px;
            width: 40px;
        }

        &__swipeable-1,
        &__swipeable-2,
        &__swipeable-3 {
            border-radius: 5em;

            p {
                font-size: 2.25rem;
            }

            button {
                height: 40pt;
                width: 160pt;
                font-size: 16px;

                img {
                    margin-left: 0.5em;
                }
            }
        }

        &__bear {
            margin: 2em 0 0 0;
            max-width: 12rem;
        }

        &__bear__body {
            width: 100%;
        }

        &__bear__speech {
            top: 30%;
            right: 12.5%;

            p {
                font-size: 1.1275rem;
                text-align: center;
                color: #090000;
                margin: 0;
            }
        }
    }

    @media (min-width: 750px) and (max-width: 780px) {
        .new-player-button {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &__swipeable-1,
        &__swipeable-2,
        &__swipeable-3 {
            p {
                font-size: 2rem;
            }
        }

        &__bear__body {
            width: 80%;
        }

        &__bear__speech-container {
            top: 0;
        }

        &__bear__speech {
            right: 0;
            top: -35px;
            p {
                font-size: 1rem;
            }
        }
    }

    @media (max-width: $screen-sm) {
        padding: 1rem;

        .avatar {
            width: 60px;
            height: 60px;

            img {
                margin-top: 0.5rem;
            }
        }

        .message-wrapper {
            padding-left: 0.5rem;

            h1 {
                font-size: 1.2rem;
                line-height: 20px;
            }

            caption {
                font-size: 1rem;
                padding-top: 5px;
            }
        }

        .bell {
            width: 40px;
            height: 40px;
        }

        .notification__count {
            border: 1px solid $button-red-dark;
            top: -12.5%;
            right: -5%;
            font-size: 1rem;
            height: 30px;
            width: 30px;
        }

        &__swipeable-1,
        &__swipeable-2,
        &__swipeable-3 {
            border-radius: 2em;

            p {
                font-size: 1.25rem;
            }

            button {
                height: 30pt;
                width: 100pt;
                font-size: 10px;

                img {
                    height: 16px;
                    width: 16px;
                    margin-left: 0.5em;
                }
            }
        }

        &__bear {
            margin: 4em 0 0 0;
            max-width: 10.5rem;
        }

        &__bear__speech {
            top: 22%;
            right: 12.5%;

            p {
                font-size: 1rem;
                text-align: center;
                color: #090000;
                margin: 0;
            }
        }
    }

    @media (min-width: 377px) and (max-width: 430px) {
        &__bear__body {
            width: 70%;
        }

        &__swipeable-1,
        &__swipeable-2,
        &__swipeable-3 {
            p {
                font-size: 1rem!important;
            }
        }

        &__bear__speech {
            p {
                font-size: 1rem!important;
            }
        }
    }

    @media (max-width: 376px) {
        &__bear__body {
            width: 70%;
        }

        &__bear__speech {
            top: 30%;
            right: 12.5%;
        }
    }

    @media (max-width: $screen-xs) {
        .avatar {
            width: 60px;
            height: 60px;

            img {
                margin-top: 0.5rem;
            }
        }

        .message-wrapper {
            padding-left: 0.5rem;

            h1 {
                font-size: 1rem;
                line-height: 15px;
            }

            caption {
                font-size: 0.775rem;
                padding-top: 0;
            }
        }

        .bell {
            width: 40px;
            height: 40px;
        }

        .notification__count {
            border: 1px solid $button-red-dark;
            top: -15%;
            right: -5%;
            font-size: 1rem;
            height: 30px;
            width: 30px;
        }

        &__swipeable-1,
        &__swipeable-2,
        &__swipeable-3 {
            border-radius: 2em;

            p {
                font-size: 1rem;
            }

            button {
                height: 20pt;
                width: 90pt;
                font-size: 10px;

                img {
                    height: 16px;
                    width: 16px;
                    margin-left: 0.5em;
                }
            }
        }

        &__bear {
            margin: 2em 0 0 0;
            max-width: 8rem;
        }

        &__bear__speech {
            top: 22%;
            right: 17.5%;

            p {
                font-size: 0.65rem;
                text-align: center;
                color: #090000;
                margin: 0;
            }
        }
    }

    @media (max-height: 500px) and (max-width: 320px) {

        &__bear {
            margin: 2em 0 0 0;
            max-width: 5rem;
        }

        &__bear__body {
            width: 100%;
        }

        &__bear__speech {
            top: 22%;
            right: -20px;

            p {
                font-size: 0.65rem;
                text-align: center;
                color: #090000;
                margin: 0;
            }
        }

        &__bear__speech::after {
            content: " ";
            position: absolute;
            top: 98%;
            left: 5%;
            margin-left: 0px;
            border-width: 15px;
            border-style: solid;
            border-color: #f3c960 transparent transparent transparent;
        }
    }

}