.modal{
    overflow: hidden;
    display: flex;
    flex-flow: column;
    max-height: 70vh;

    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9000;
    }

    position: fixed;
    background: #fff;
    padding: 1.75rem;
    z-index: 9001;
    border-radius: 1.5rem;
    width:100vw;
    height: 70vh;

    &--notification {
        top: 0%;
        left: 50%;
        transform: translate(-50%, 0%);

        border-top-left-radius: 0;    
        border-top-right-radius: 0;
        overflow: auto !important;
    }

    &--badge {
        top: 100%;
        //opacity: 0;
        // left: 50%;
        // bottom: 50%;
        // transform: translate(-50%, -50%);
        animation: slideUpModal 250ms forwards;
    }

    &--popup {
        //top: 50%;
        left: 50%;
        bottom: 100%;
        // transform: translateX(-50%);
        width: 50vw;
        height: 50vh;
        animation: slideDownPopup 500ms forwards;
    }

    &__close {
        position: absolute;
        top: 1%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 29px;
        height: 4px;
        background: #DFDEDF 0% 0% no-repeat padding-box;
        border-radius: 2px;
        opacity: 0.5;
    }

    &__content {
        height: 100%;
        flex: 1;
    }
    .modal__notifications {

        .new-true {
            background-color: $bg-orange !important;
        }

        .modal__notifications__header {
            h1 {
                color: $text-color-main;
            }
        }

        .modal__notifications__description {
            border: 1px solid $button-red-dark;
            padding: 0.25em;
            background-color: $bg-light;
            border-radius: 10px;
            color: $text-color-main;
            font-family: $font-filson-pro;
            margin-top: 0.25em;
        }

    }


     @keyframes slideUpModal {
        from {
            transform: translateY(0%);
            opacity: 0;
        }
        to {
            transform: translateY(-95%);
            opacity: 1;
        }
    }

    @keyframes slideDownPopup {
        from {
            transform: translate(-50%, 0%);
            opacity: 0;
        }
        to {
            transform: translate(-50%, 150%);
            opacity: 1;
        }
    }

    @media (max-width: $screen-lg), (min-width: $screen-lg) {

    }
    
    @media (max-width: $screen-md) {

    }
    
    
    @media (max-width: $screen-sm) {
        padding: 1.25rem;

        &--popup {
            width: 90vw;
            height: 60vh;
        }
    }
    
    @media (max-width: $screen-xs) {

    }
}