.App {
    width: 100vw;
    height: 100vh;
    // max-width: 100rem;
    margin-inline: auto;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  overflow-y: hidden;
  font-family: $font-filson-soft;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  user-select: none;
}

img, picture {
  display: block;
  max-width: 100%;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

::placeholder {
  opacity: 0.8;
}

.page {
    height: $pageHeight;
}

.bg-blue {
  background-color: $bg-blue;
}

.bg-light {
  background-color: $bg-light;
}

.bg-orange {
  background-color: $bg-orange;
}

.bg-yellow {
  background-color: $bg-yellow;
}

.bg-gray {
  background-color: $bg-gray;
}

.text-center {
  text-align: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.react-swipeable-view-container {
  height: 100%;
}

.swipeable-container {
  height: 100%;
}

.title-wrapper {
  img {
      padding: 0.5rem;
      border-radius: 50%;
      cursor: pointer;
      background-color: $button-red;
      box-shadow: 0 4px 0 $button-red-dark;
  }
}

.MuiMenu-paper {
  max-height: 300px !important;

  .MuiMenuItem-root {
    font-size: 2rem;
    @extend .flex-center;
  }
}

.button-wrapper {
  @extend .flex-center;
  width: 100%;
}

.button-wrapper-yes-no {
  @extend .flex-row;
  gap: 2em;
}

.expand-page {
  padding: 0 !important;
}

.expand-title {
  padding: 1em !important;
}

@media (max-width: $screen-lg) {
  .title-wrapper {
    img {
        width: 60px;
        height: 60px;
    }
  }

}

@media (max-width: $screen-md) {
  .title-wrapper {
      img {
          width: 50px;
          height: 50px;
      }
  }
}


@media (max-width: $screen-sm) {
  h1 {
      font-size: 1.5em;
  }
  .title-wrapper {
      img {
          width: 40px;
          height: 40px;
      }
  }
}

@media (max-width: $screen-xs) {
  .button-wrapper-yes-no {
    gap: 0.5em;
  }

  .title-wrapper {
    img {
        width: 30px;
        height: 30px;
    }
}
}