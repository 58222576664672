.astigmatism-test, .astigmatism-test-instructions {
    
    h1 {
        color: #FFF;
        font-family: $font-filson-pro;
    }

    .flex-col {
        align-items: center;
        height:100%;
    }

    .flex-row {
        align-self: flex-start;
        align-items: center;

        img {
            z-index: 1;
        }
    }

    &__test__progressbar-container {
        @extend .flex-center;
        margin-top: -0.5em;
    }

    &__test__question-container {
        margin: 5em 0;
    }

    &__test__image-container {
        width: 90%;
        @extend .flex-center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 0px $button-red;
        border-radius: 30px;
        opacity: 1;
        padding: 7em 0;

        img {
            width: 90%;
        }

        &--animation-expand {
            animation: imageContainerExpand 500ms forwards;
        }
    }

    &__test__button-container {
        flex: 1;
        @extend .flex-center;
        gap: 1em;
        button {
            width: 245px; 
        }
    }

    @keyframes imageContainerExpand {
        from {
            position: relative;
        }
        to {
            width: 100%;
            height: 100%;
            position: fixed;
            border-radius: 0px;
        }

    }

    @media (max-width: $screen-lg), (min-width: $screen-lg) {
        padding: 3em 2em; 
        
        .flex-row {
            h1 {
                font-size: 2.5em;
                margin-left: 1em;
            }
        }

        &__test__progressbar-container {
            width: 90%;
    
            img {
                width: 80%;
            }
        }
    }
    
    @media (max-width: $screen-md) {
        .flex-row {
            h1 {
                font-size: 2em;
                margin-left: 1em;
            }
        }
        
        &__test__question-container {
            margin: 2em 0;
        }

        &__test__image-container {
            padding: 5em 0;
        }

    }

    @media (max-width: $screen-sm) {
        padding: 1em; 

        h2 {
            font-size: 1em;
            margin-bottom: 0.5em;
        }

        h1 {
            font-size: 1.25em;
        }

        .flex-row {
            h1 {
                font-size: 1.25em;
                margin-left: 0.5em;
            }
        }

        &__test__progressbar-container {
            width: 100%;
    
            img {
                width: 100%;
            }
        }

        &__test__question-container {
            margin: 1em 0;
        }

        &__test__image-container {
            padding: 5em 0;
        }

        &__test__button-container {
            button {
                width: 145px; 
            }
        }

    }
    
    @media (max-width: $screen-xs) {

        &__test__image-container {
            padding: 4em 0;
        }

        &__test__button-container {
            button {
                width: 125px; 
            }
        }
    }
}